
import { defineComponent } from 'vue'
import Navbar from '@/components/Navbar.vue'
import cookies from 'js-cookie'

export default defineComponent({
  components: { Navbar },
  created() {
    if (cookies.get('username')) {
      cookies.set('username', <string> cookies.get('username'), {
        expires: 14
      })
    }
  }
})
